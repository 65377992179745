import React, {Component} from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import Navigation from './Navigation'
import Settings from '../pages/Settings'
import StashPricing from '../pages/StashPricing'

interface Props {
}

interface State {
  sessionId: string | null
}

class Application extends Component<Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = {
      sessionId: localStorage.getItem('sessionId')
    }

    this.setSessionId = this.setSessionId.bind(this)
  }

  setSessionId (sessionId: string) {
    localStorage.setItem('sessionId', sessionId)
    this.setState({sessionId})
  }

  render () {
    const hasSessionId = !!this.state.sessionId

    return (
      <Router>
        <>
          <Navigation
            hasSessionId={hasSessionId}
          />

          <div className="mt-4 mx-auto" style={{width: 1140}}>
            <Switch>
              {/* Redirect to page from the initial page load */}
              <Route
                path="/" exact
                render={() => (
                  hasSessionId
                    ? <Redirect to="/stash-pricing"/>
                    : <Redirect to="/settings"/>
                )}
              />

              {/* Public routes */}
              <Route
                path="/settings"
                component={() => (
                  <Settings
                    hasSessionId={hasSessionId}
                    setSessionId={this.setSessionId}
                  />
                )}
              />

              {/* Authenticated routes */}
              {hasSessionId && (
                <>
                  <Route path="/stash-pricing" component={() => <StashPricing />}/>
                </>
              )}

              {/* Missing route -> Redirect to initial page load page */}
              <Route render={() => <Redirect to="/"/>}/>
            </Switch>
          </div>
        </>
      </Router>
    )
  }
}

export default Application
