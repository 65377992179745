import React, {Component} from 'react'
import _get from 'fast-get'
import {PoeItem} from '../actions/fetchStashTabs'
import CurrencyIcon from '../../../common/CurrencyIcon'
import Checkbox from '../../../common/Checkbox'
import ItemListing, {ListingHighlightModes} from './ItemListing'
import Pagination from '../../../common/Pagination'
import {searchStrategy} from '../actions/fetchItemPrice'
import PoeTradeIcon from '../assets/poetrade.png'

// TODO Pull in conversion rates and add additional currencies
const EXALT_PRICE = 180
const PAGE_SIZE = 25

interface Props {
  items: Array<PoeItem>
  updatePricing: Function
  listingHighlightMode: ListingHighlightModes
  listingAmount: number
}

interface State {
  page: number
  sortKey: string
  sortDirection: 1 | -1
}

class ItemTable extends Component<Props, State> {
  constructor (props: Readonly<Props>) {
    super(props);

    this.state = {
      page: 0,
      sortKey: 'pricing.totalChaosEquivalent',
      sortDirection: -1
    }
  }

  sortedItems () {
    const items = this.props.items

    items.sort((a, b) => {
      const aValue = _get(a, this.state.sortKey)
      const bValue = _get(b, this.state.sortKey)

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return this.state.sortDirection * aValue.localeCompare(bValue)
      }

      return this.state.sortDirection * (aValue - bValue)
    })

    return items
  }

  renderHeader (label: string, key: string, width: number | 'auto' | null = null, sortable: boolean = true) {
    const onClick = sortable ? () => this.onHeaderClick(key) : () => false

    return (
      <th
        scope="col"
        style={{
          borderTop: 'none',
          cursor: sortable ? 'pointer' : 'default',
          width: width ? width + 'px' : 'auto'
        }}
        onClick={onClick}
      >
        <div className='d-flex align-items-center'>
          {label}
          {this.renderHeaderSortIndicator(key)}
        </div>
      </th>
    )
  }

  renderHeaderSortIndicator (key: string) {
    if (this.state.sortKey !== key) return null

    return (
      <div className='d-flex align-items-center ml-2'>
        {this.state.sortDirection === 1 ? <AscendingSortIndicator/> : <DescendingSortIndicator/>}
      </div>
    )
  }

  onHeaderClick (key: string) {
    if (this.state.sortKey !== key) {
      return this.setState({sortKey: key, sortDirection: 1})
    }

    if (this.state.sortDirection === 1) {
      return this.setState({sortDirection: -1})
    } else {
      return this.setState({sortDirection: 1})
    }
  }

  renderPricingInput (item: PoeItem) {
    if (!item.pricing) return <i>Loading...</i>

    const updatePricing = this.props.updatePricing
    const hash = item.hash
    const pricing = item.pricing

    return (
      <div>
        <div className='d-flex align-items-center'>
          <Checkbox
            checked={pricing.usePrice}
            onChange={(usePrice) => updatePricing(hash, {...pricing, usePrice: usePrice})}
          />

          <input
            className="form-control form-control-sm mr-2"
            value={pricing.amount}
            style={{width: 75}}
            onChange={(e) => {
              const amount = parseFloat(e.target.value)
              const chaosEquivalent = pricing.currency === 'chaos' ? amount : amount * EXALT_PRICE

              updatePricing(hash, {
                ...pricing,
                chaosEquivalent: chaosEquivalent,
                totalChaosEquivalent: chaosEquivalent * item.count,
                amount: amount
              })
            }}
          />

          <select
            className="form-control form-control-sm"
            value={pricing.currency}
            style={{width: 50}}
            onChange={(e) => {
              const currency = e.target.value
              const chaosEquivalent = currency === 'chaos' ? pricing.amount : pricing.amount * EXALT_PRICE

              updatePricing(hash, {
                ...pricing,
                chaosEquivalent: chaosEquivalent,
                totalChaosEquivalent: chaosEquivalent * item.count,
                currency: currency
              })
            }}
          >
            <option value='chaos'>C</option>
            <option value='exa'>EX</option>
          </select>
        </div>
      </div>
    )
  }

  render () {
    const items = this.sortedItems()

    if (items.length === 0) {
      return <div>There are no items in your stash tab or your filters do not match any items.</div>
    }

    const countSum = items
      .map(item => item.count)
      .reduce((a, b) => a + b, 0)

    const chaosEquivalentSum = items
      .map(item => item.pricing ? item.pricing.totalChaosEquivalent : 0)
      .reduce((a, b) => a + b, 0)

    return (
      <div>
        <table className="table mb-0">
        <thead>
        <tr>
          {this.renderHeader('Amount', 'count', 110)}
          {this.renderHeader('Name', 'name', 'auto')}
          {this.renderHeader('Cheapest Listings', 'listings', 250, false)}
          {this.renderHeader('', '', 30, false)}
          {this.renderHeader('Price', 'pricing.chaosEquivalent', 200)}
          {this.renderHeader('Total Price', 'pricing.totalChaosEquivalent', 200)}
        </tr>
        </thead>
        <tbody>
        {items.slice(this.state.page * PAGE_SIZE, this.state.page * PAGE_SIZE + PAGE_SIZE).map((item) => (
          <tr key={item.hash}>
            {/* Count */}
            <td style={{verticalAlign: 'middle'}}>
              {format(item.count)}
            </td>

            {/* Icon and Name */}
            <td style={{verticalAlign: 'middle'}}>
              <div className='d-flex align-items-center'>
                <img src={item.icon} className='mr-2' alt='' height={28}/>
                {item.name}
              </div>

              {/* Additional subtext for gems */}
              {item.type === 'gem' && (
                <small className="text-muted">
                  Corrupted: {item.properties.corrupted ? 'Yes' : 'No'}
                  {' / '}
                  Quality: {item.properties.quality || 0}%
                  {' / '}
                  Level: {item.properties.level}
                </small>
              )}

              {/* Additional subtext for enchant bases */}
              {item.properties.enchant && (
                <small className="text-muted">
                  {item.properties.enchant}
                </small>
              )}

              {/* Additional subtext for prophecies */}
              {item.properties.prophecyText && (
                <small className="text-muted">
                  {item.properties.prophecyText}
                </small>
              )}
            </td>

            {/* Listings */}
            <td style={{verticalAlign: 'middle'}}>
              {!item.listings && (
                <i>Loading...</i>
              )}

              {item.listings && (
                <div>
                  <div
                    className='d-flex align-items-center flex-wrap'
                    style={{marginBottom: -4}}
                  >
                    {item.listings
                      .slice(0, this.props.listingAmount)
                      .map((listing, index) => (
                        <ItemListing
                          key={index}
                          listing={listing}
                          highlightMode={this.props.listingHighlightMode}
                        />
                      ))}
                  </div>

                  <small className="text-muted">
                    {item.listings.length} total listings
                  </small>
                </div>
              )}
            </td>

            {/* poe.trade button */}
            <td style={{verticalAlign: 'middle'}}>
              <a href={poeTradeLink(item)} target='_blank'>
                <img src={PoeTradeIcon} alt='poe.trade' />
              </a>
            </td>

            {/* Price */}
            <td style={{verticalAlign: 'middle'}}>
              {this.renderPricingInput(item)}
            </td>

            {/* Total price */}
            <td style={{verticalAlign: 'middle'}}>
              {item.pricing === undefined && (
                <i>Loading...</i>
              )}

              {item.pricing !== undefined && (
                <div className='d-flex align-items-center'>
                  <h3 className='mr-2 mb-0'>{format(item.pricing.totalChaosEquivalent)}</h3>
                  <CurrencyIcon icon='chaos' size={26}/>
                </div>
              )}
            </td>
          </tr>
        ))}

        <tr>
          <td>{format(countSum)}</td>
          <td/>
          <td/>
          <td/>
          <td/>
          <td>
            <div className='d-flex align-items-center'>
              <h3 className='mr-2 mb-0'>{format(chaosEquivalentSum)}</h3>
              <CurrencyIcon icon='chaos' size={26}/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

        <Pagination
          currentPage={this.state.page}
          onChange={(page) => this.setState({page})}
          pageSize={PAGE_SIZE}
          totalElements={items.length}
        />
      </div>
    )
  }
}

type ItemMod = [string, string]

const DEFAULT_FILTERS: Array<ItemMod> = [
  ['league', ''],
  ['type', ''],
  ['base', ''],
  ['name', ''],
  ['dmg_min', ''],
  ['dmg_max', ''],
  ['aps_min', ''],
  ['aps_max', ''],
  ['crit_min', ''],
  ['crit_max', ''],
  ['dps_min', ''],
  ['dps_max', ''],
  ['edps_min', ''],
  ['edps_max', ''],
  ['pdps_min', ''],
  ['pdps_max', ''],
  ['armour_min', ''],
  ['armour_max', ''],
  ['evasion_min', ''],
  ['evasion_max', ''],
  ['shield_min', ''],
  ['shield_max', ''],
  ['block_min', ''],
  ['block_max', ''],
  ['sockets_min', ''],
  ['sockets_max', ''],
  ['link_min', ''],
  ['link_max', ''],
  ['sockets_r', ''],
  ['sockets_g', ''],
  ['sockets_b', ''],
  ['sockets_w', ''],
  ['linked_r', ''],
  ['linked_g', ''],
  ['linked_b', ''],
  ['linked_w', ''],
  ['rlevel_min', ''],
  ['rlevel_max', ''],
  ['rstr_min', ''],
  ['rstr_max', ''],
  ['rdex_min', ''],
  ['rdex_max', ''],
  ['rint_min', ''],
  ['rint_max', ''],
  ['mod_name', ''],
  ['mod_min', ''],
  ['mod_max', ''],
  ['mod_weight', ''],
  ['group_type', 'And'],
  ['group_min', ''],
  ['group_max', ''],
  ['group_count', ''],
  ['q_min', ''],
  ['q_max', ''],
  ['level_min', ''],
  ['level_max', ''],
  ['ilvl_min', ''],
  ['ilvl_max', ''],
  ['rarity', ''],
  ['progress_min', ''],
  ['progress_max', ''],
  ['sockets_a_min', ''],
  ['sockets_a_max', ''],
  ['map_series', ''],
  ['altart', ''],
  ['identified', ''],
  ['corrupted', ''],
  ['shaper', ''],
  ['elder', ''],
  ['crafted', ''],
  ['enchanted', ''],
  ['mirrored', ''],
  ['veiled', ''],
  ['seller', ''],
  ['thread', ''],
  ['online', ''],
  ['capquality', ''],
  ['buyout_min', ''],
  ['buyout_max', ''],
  ['buyout_currency', ''],
  ['has_buyout', ''],
  ['exact_currency', '']
]

function poeTradeLink (item: PoeItem): string {
  const filters = mergeFilters(DEFAULT_FILTERS, [
    ['league', global.CURRENT_LEAGUE],
    ['has_buyout', '1'],
    ['online', 'x'],
    ...searchStrategy(item)
  ])

  const queryString = filters.map(filter => {
    return `${filter[0]}=${encodeURIComponent(filter[1])}`
  })

  return 'http://poe.trade/search?' + queryString.join('&')
}

function mergeFilters (
  defaults: Array<ItemMod>,
  overwrites: Array<ItemMod>
): Array<ItemMod> {
  const overwrittenKeys = overwrites.map(x => x[0])

  return [
    ...defaults.filter(x => !overwrittenKeys.includes(x[0])),
    ...overwrites
  ]
}

function format (number: number): string {
  return Math.round(number).toLocaleString()
}

const AscendingSortIndicator = () =>
  <div
    style={{
      width: 0,
      height: 0,
      borderLeft: `5px solid transparent`,
      borderRight: `5px solid transparent`,
      borderBottom: `5px solid #313131`
    }}
  />

const DescendingSortIndicator = () =>
  <div
    style={{
      width: 0,
      height: 0,
      borderLeft: `5px solid transparent`,
      borderRight: `5px solid transparent`,
      borderTop: `5px solid #313131`
    }}
  />

export default ItemTable
