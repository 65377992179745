import React from 'react'

interface Props {
  icon: string
  size: number
}

const CurrencyIcon = (props: Props) => (
  <img
    src={'https://poe.trade/static/currency/' + props.icon + '.png'}
    alt={props.icon}
    width={props.size}
  />
)

export default CurrencyIcon
