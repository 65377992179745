import React, {Component} from 'react'
import throttle from 'lodash.throttle'
import pLimit from 'p-limit'
import fetchStashTabList, {PoeStashTab} from './actions/fetchStashTabList'
import fetchStashTabs, {PoeItem, PoePricing} from './actions/fetchStashTabs'
import fetchItemPrice from './actions/fetchItemPrice'
import applyPricingOptions, {PricingOptions} from './actions/applyPricingOptions'
import Card from '../../common/Card'
import Checkbox from '../../common/Checkbox'
import CurrencyIcon from '../../common/CurrencyIcon'
import ForumPostGenerator from './components/ForumPostGenerator'
import ItemTable from './components/ItemTable'
import StashTabSelection from './components/StashTabSelection'
import {ListingHighlightModes} from './components/ItemListing'

const limit = pLimit(5)

interface Props {
}

interface State {
  // Stash tabs
  tabs: Array<PoeStashTab> | null
  tabIndexes: Array<number>

  // Items
  items: Array<PoeItem> | null // The items from the stash, with their listings applied
  processedItems: Array<PoeItem> | null // The items after processing (calculate suggested / filter)
  itemsLoading: boolean
  pricesLoaded: number

  // Pricing options
  consideredListingsCount: string
  minimumChaosPrice: string
  maximumListingAge: string
  removeListingOutliers: boolean

  // Filter options
  searchProperty: string
  searchStash: string
  listingHighlightMode: ListingHighlightModes
  listingAmount: number
}

class StashPricing extends Component<Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = {
      tabs: null,
      tabIndexes: [],

      items: null,
      processedItems: null,
      itemsLoading: false,
      pricesLoaded: 0,

      consideredListingsCount: '2',
      minimumChaosPrice: '0',
      maximumListingAge: '7',
      removeListingOutliers: true,

      searchProperty: '',
      searchStash: '',
      listingHighlightMode: 'age',
      listingAmount: 10
    }

    this.throttleApplyPricingOptions = throttle(this.applyPricingOptions.bind(this), 500)
    this.updatePricing = this.updatePricing.bind(this)
  }

  componentDidMount (): Promise<void> {
    return this.loadStashTabList()
  }

  async loadStashTabList () {
    this.setState({tabs: null})

    const tabs = await fetchStashTabList()
    this.setState({tabs, items: null, processedItems: null, itemsLoading: false, pricesLoaded: 0})
  }

  getPricingOptions (): PricingOptions {
    const state = this.state

    return {
      consideredListingsCount: parseInt(state.consideredListingsCount, 10),
      minimumChaosPrice: parseInt(state.minimumChaosPrice, 10),
      maximumListingAge: parseInt(state.maximumListingAge, 10) * 24 * 60 * 60 * 1000,
      removeListingOutliers: state.removeListingOutliers
    }
  }

  async loadStashTabs () {
    this.setState({items: null, processedItems: null, itemsLoading: true, pricesLoaded: 0})

    // Fetch the items from the stash tab
    const items = await fetchStashTabs(this.state.tabIndexes)
    this.setState({items, processedItems: items, itemsLoading: false})

    // Fetch and merge the prices into the items one by one
    const fetchPriceForItem = async (item: PoeItem) => {
      const pricedItem = await fetchItemPrice(item)

      this.setState(state => {
        if (!state.items) return state

        let items = state.items.map(item => {
          if (item.hash === pricedItem.hash) return pricedItem
          return item
        })

        return {...state, items, pricesLoaded: state.pricesLoaded + 1}
      }, () => this.throttleApplyPricingOptions())
    }

    await Promise.all(items.map((item) => limit(() => fetchPriceForItem(item))))
  }

  applyPricingOptions () {
    const state = this.state
    if (!state.items) return

    const options = this.getPricingOptions()
    this.setState({processedItems: applyPricingOptions(state.items, options)})
  }

  throttleApplyPricingOptions () {
    // Gets overwritten in constructor
  }

  updatePricing (hash: string, pricing: PoePricing) {
    if (!this.state.processedItems) return

    const processedItems = this.state.processedItems.map(item => {
      if (item.hash !== hash) return item
      return {...item, pricing: pricing}
    })

    this.setState({processedItems})
  }

  filterItems (items: Array<PoeItem>): Array<PoeItem> {
    const state = this.state

    const searchProperty = state.searchProperty.toLowerCase()
    const searchStash = state.searchStash
      ? 'Stash' + (parseInt(state.searchStash, 10) + 1)
      : false

    return items.filter(item => {
      if (searchProperty && !item.blob.includes(searchProperty)) {
        return false
      }

      if (searchStash && item.stashLocation.stash !== searchStash) {
        return false
      }

      return true
    })
  }

  renderStashTabs () {
    return (
      <Card>
        <h4 className='mb-3'>Stash tabs</h4>

        <div className="mb-3 d-flex flex-wrap">
          {!this.state.tabs && (
            <div>Loading your stash tabs...</div>
          )}

          {this.state.tabs && this.state.tabs.map(tab => (
            <StashTabSelection
              key={tab.index}
              tab={tab}
              checked={this.state.tabIndexes.includes(tab.index)}
              onChange={(checked) => {
                const tabIndexes = checked
                  ? this.state.tabIndexes.concat(tab.index)
                  : this.state.tabIndexes.filter(x => x !== tab.index)

                this.setState({tabIndexes})
              }}
            />
          ))}
        </div>

        <div className='d-flex align-items-center'>
          <button
            className="btn btn-primary"
            onClick={() => this.loadStashTabs()}
            disabled={this.state.tabIndexes.length === 0}
          >
            Load stash tabs and item listings
          </button>

          <button
            className="btn btn-outline-secondary ml-auto"
            onClick={() => this.loadStashTabList()}
          >
            Reload tabs
          </button>
        </div>
      </Card>
    )
  }

  renderPricingOptions () {
    return (
      <Card className='flex-grow-1 w-50 mr-3'>
        <h4 className='mb-1'>Pricing options</h4>
        <small className='d-block text-muted mb-3'>
          Changing any of these options will overwrite all custom entered prices.
          Note that only listings from sellers that are currently online are considered for better price quality.
        </small>

        <div className="d-flex align-items-center mb-3">
          Only consider listings created in the last
          <input
            type='number'
            className="form-control form-control-sm ml-2 mr-2"
            onChange={(x) => {
              const maximumListingAge = parseFloat(x.target.value)
              if (maximumListingAge <= 0) return

              this.setState(
                {maximumListingAge: '' + maximumListingAge},
                () => this.applyPricingOptions()
              )
            }}
            value={this.state.maximumListingAge}
            style={{width: 75}}
          />
          days
        </div>

        <div className="d-flex align-items-center mb-3">
          <Checkbox
            checked={this.state.removeListingOutliers}
            label={(
              <span>
                Remove heavily under/overpriced listings{' '}
                <span className='text-muted'>(only works for 5+ listings)</span>
              </span>
            )}
            onChange={(checked) => {
              this.setState({removeListingOutliers: checked}, () => this.applyPricingOptions())
            }}
          />
        </div>

        <div className="d-flex align-items-center mb-3">
          Use the cheapest
          <input
            type='number'
            className="form-control form-control-sm ml-2 mr-2"
            onChange={(x) => {
              const consideredListingsCount = parseInt(x.target.value, 10)
              if (consideredListingsCount <= 0) return

              this.setState(
                {consideredListingsCount: '' + consideredListingsCount},
                () => this.applyPricingOptions()
              )
            }}
            value={this.state.consideredListingsCount}
            style={{width: 75}}
          />
          listings for suggested price calculation
        </div>

        <div className="d-flex align-items-center">
          Only show items with a suggested price of at least
          <input
            type='number'
            className="form-control form-control-sm ml-2 mr-2"
            onChange={(x) => {
              const minimumChaosPrice = parseFloat(x.target.value)
              if (minimumChaosPrice < 0) return

              this.setState(
                {minimumChaosPrice: '' + minimumChaosPrice},
                () => this.applyPricingOptions()
              )
            }}
            value={this.state.minimumChaosPrice}
            style={{width: 75}}
          />
          <CurrencyIcon icon='chaos' size={20}/>
        </div>
      </Card>
    )
  }

  renderFilterOptions () {
    return (
      <Card className='flex-grow-1 w-50'>
        <h4 className='mb-1'>Filter options</h4>
        <small className='d-block text-muted mb-3'>
          Filtering items will only affect the displayed items and not change any prices.
        </small>

        <div className="mb-3">
          <div>
            Only display items where name or property matches
          </div>

          <input
            className="form-control form-control-sm mt-1"
            onChange={(e) => this.setState({searchProperty: e.target.value})}
            value={this.state.searchProperty}
          />
        </div>

        <div className="mb-3">
          <div>
            Only display items in stash tab
          </div>

          <select
            className="form-control form-control-sm mt-1"
            onChange={(e) => this.setState({searchStash: e.target.value})}
            value={this.state.searchStash}
          >
            <option value=''>Select stash tab...</option>
            {this.state.tabs && this.state.tabs.map(tab => (
              <option key={tab.index} value={tab.index}>{tab.name}</option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <div className='d-flex'>
            <div>
              Displayed listings
            </div>

            <div className='ml-auto'>
              {this.state.listingAmount}
            </div>
          </div>

          <input
            type='range'
            className='custom-range'
            min='5'
            max='50'
            step='5'
            onChange={(e) => this.setState({listingAmount: parseInt(e.target.value)})}
            value={this.state.listingAmount}
          />
        </div>

        <div>
          <div>
            Listing background highlight
          </div>

          <select
            className="form-control form-control-sm mt-1"
            onChange={(e) => this.setState({listingHighlightMode: e.target.value as ListingHighlightModes})}
            value={this.state.listingHighlightMode}
          >
            <option value='none'>None</option>
            <option value='age'>Listing age (green = recent, yellow = 3-7 days, red = 7+ days)</option>
            <option value='seller'>Selling account (unique color per account name)</option>
          </select>
        </div>
      </Card>
    )
  }

  renderProgressBar () {
    if (this.state.itemsLoading || !this.state.items) return null
    const percentDone = (this.state.pricesLoaded / this.state.items.length) * 100

    return (
      <div className="progress mt-2">
        <div
          className="progress-bar"
          role="progressbar"
          style={{width: percentDone + '%'}}
        />
      </div>
    )
  }

  renderStashItems () {
    const state = this.state

    if (state.itemsLoading) {
      return <Card className="mt-4 mb-4">Loading your stash items...</Card>
    }

    // Items are loaded but not processed (I don't think this can happen, but better safe than sorry)
    if (!state.processedItems) return null

    return (
      <>
        <Card className="mt-2 mb-2">
          <ItemTable
            items={this.filterItems(state.processedItems)}
            updatePricing={this.updatePricing}
            listingHighlightMode={this.state.listingHighlightMode}
            listingAmount={this.state.listingAmount}
          />
        </Card>

        <Card className="mb-4">
          <ForumPostGenerator items={state.processedItems}/>
        </Card>
      </>
    )
  }

  render () {
    return (
      <>
        {/* Stash tabs */}
        {this.renderStashTabs()}

        {/* Pricing options and filter options */}
        <div className='d-flex align-items-start mt-3'>
          {this.renderPricingOptions()}
          {this.renderFilterOptions()}
        </div>

        {/* Progress bar for loading item listings */}
        {this.renderProgressBar()}

        {/* Stash items */}
        {this.renderStashItems()}
      </>
    )
  }
}

export default StashPricing
