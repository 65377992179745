interface PoeApiStashTab {
  i: number
  n: string
  colour: {
    r: number
    g: number
    b: number
  }
}

export interface PoeStashTab {
  index: number
  name: string
  color: string
  background: string
}

export default async function fetchStashTabList (): Promise<Array<PoeStashTab>> {
  const options = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({sessionId: localStorage.getItem('sessionId')})
  }

  const response = await window.fetch(`/api/${global.CURRENT_LEAGUE}/stash-tabs`, options)
  const json = await response.json()

  return json.map((x: PoeApiStashTab) => ({
    index: x.i,
    name: x.n,
    color: x.colour.r + x.colour.g + x.colour.b > 128 * 3 ? 'black' : 'white',
    background: `rgb(${x.colour.r}, ${x.colour.g}, ${x.colour.b})`
  }))
}
