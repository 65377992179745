import React, {Component} from 'react'

interface Props {
  checked: boolean
  label?: React.ReactNode
  onChange: (checked: boolean) => void
}

class Checkbox extends Component<Props> {
  private readonly id: string

  constructor (props: any) {
    super(props)

    this.id = `checkbox-${Math.round(Math.random() * 10000)}`
  }

  render () {
    const props = this.props

    return (
      <div className="custom-control custom-checkbox">
        <input
          id={this.id}
          type="checkbox"
          className="custom-control-input"
          checked={props.checked}
          onChange={(e) => props.onChange(e.target.checked)}
        />

        <label
          htmlFor={this.id}
          className="custom-control-label"
        >
          {props.label || <span>&nbsp;</span>}
        </label>
      </div>
    )
  }
}

export default Checkbox
