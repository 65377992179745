import React from 'react'
import randomColor from 'randomcolor'
import CurrencyIcon from '../../../common/CurrencyIcon'
import {PoeListing} from '../actions/fetchStashTabs'

export type ListingHighlightModes = 'none' | 'age' | 'seller'

interface Props {
  listing: PoeListing
  highlightMode: ListingHighlightModes
}

const ItemListing = (props: Props) => (
  <small
    className='d-flex align-items-center mr-1 mb-1'
    style={{
      padding: '0px 6px',
      background: backgroundForListing(props.highlightMode, props.listing)
    }}
    title={`This listing was created by ${props.listing.seller} at ${props.listing.timestamp.toLocaleString()}`}
  >
    <div style={{marginRight: 4}}>{props.listing.amount}</div>
    <CurrencyIcon icon={props.listing.currency} size={14}/>
  </small>
)

function backgroundForListing (type: ListingHighlightModes, listing: PoeListing): string {
  if (type === 'age') {
    return backgroundFromAge(listing.timestamp)
  }

  if (type === 'seller') {
    return backgroundFromSeller(listing.seller)
  }

  return '#eee'
}

function backgroundFromAge (timestamp: Date) {
  if (timestamp.getTime() < new Date().getTime() - 7 * 24 * 60 * 60 * 1000) {
    return 'rgba(255, 0, 0, 0.2)'
  }

  if (timestamp.getTime() < new Date().getTime() - 3 * 24 * 60 * 60 * 1000) {
    return 'rgba(255, 255, 0, 0.2)'
  }

  return 'rgba(0, 255, 0, 0.2)'
}

function backgroundFromSeller (seller: string) {
  return randomColor({
    luminosity: 'light',
    seed: seller,
    format: 'hex'
  })
}

export default ItemListing
