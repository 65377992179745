import outliers from 'outliers'
import {PoeItem, PoeListing, PoePricing} from './fetchStashTabs'

export interface PricingOptions {
  maximumListingAge: number
  removeListingOutliers: boolean
  consideredListingsCount: number
  minimumChaosPrice: number
}

function applyPricingOptions (items: Array<PoeItem>, options: PricingOptions): Array<PoeItem> {
  return items
    .map(item => ({...item, listings: filteredListings(item, options)}))
    .map(item => ({...item, pricing: pricingForItem(item, options)}))
    .filter(item => item.pricing === undefined || item.pricing.amount >= options.minimumChaosPrice)
}

function filteredListings (item: PoeItem, options: PricingOptions): Array<PoeListing> | undefined {
  // No listings loaded yet, return "undefined" to show that
  if (!item.listings) return undefined

  let listings = item.listings

  // Filter listings below the maximum age
  const maxAge = new Date().getTime() - options.maximumListingAge
  listings = listings.filter(x => x.timestamp.getTime() >= maxAge)

  // Only consider the first 25 items because else the outlier detection does not work properly
  listings = listings.slice(0, 25)

  // Filter listing outliers using interquartile range
  // https://en.wikipedia.org/wiki/Interquartile_range#Interquartile_range_and_outliers
  if (options.removeListingOutliers) {
    listings = listings.filter(outliers('chaosEquivalent'))
  }

  return listings
}

function pricingForItem (item: PoeItem, options: PricingOptions): PoePricing | undefined {
  // No listings loaded yet, return "undefined" to show that
  if (!item.listings) return undefined

  // Grab the listings that are considered for pricing
  const consideredListings = item.listings.slice(0, options.consideredListingsCount)

  // Calculate the suggested price average
  let amount = 0
  let chaosEquivalent = 0

  if (consideredListings.length > 0) {
    amount = consideredListings.reduce((a, b) => a + b.chaosEquivalent, 0) / consideredListings.length
    amount = Math.round(amount * 100) / 100

    chaosEquivalent = Math.round(amount * 100) / 100
  }

  return {
    usePrice: true,
    chaosEquivalent: chaosEquivalent,
    totalChaosEquivalent: chaosEquivalent * item.count,
    amount: amount,
    currency: 'chaos'
  }
}

export default applyPricingOptions
