import React from 'react'
import ReactDOM from 'react-dom'
import Application from './components/routing/Application'

import './custom.css'

global.CURRENT_LEAGUE = 'Harvest'

ReactDOM.render(
  <Application />,
  document.getElementById('root')
)
