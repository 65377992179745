import React from 'react'
import {NavLink} from 'react-router-dom'

interface NavigationProps {
  hasSessionId: boolean
}

const Navigation = (props: NavigationProps) => (
  <nav className="c-nav">
    <div className="mx-auto d-flex align-items-center" style={{width: 1140}}>
      <h3 className='mr-5 mb-1'>Path of Efficiency</h3>

      {props.hasSessionId && (
        <>
          <NavigationLink to='/stash-pricing'>Stash Pricing</NavigationLink>
        </>
      )}

      <div className='ml-auto text-muted'>
        {global.CURRENT_LEAGUE} League
      </div>

      <NavigationLink to='/settings' className='ml-3'>Settings</NavigationLink>
    </div>
  </nav>
)

interface NavigationLinkProps {
  to: string
  children: React.ReactNode
  className?: string
}

const NavigationLink = (props: NavigationLinkProps) => (
  <NavLink
    className={'c-nav-item ' + props.className || ''}
    activeClassName='c-nav-active'
    to={props.to}
    exact
  >
    {props.children}
  </NavLink>
)

export default Navigation
