import React, {Component} from 'react'
import Card from '../../common/Card'

interface Props {
  hasSessionId: boolean
  setSessionId: (sessionId: string) => void
}

interface State {
  sessionId: string
}

class Settings extends Component<Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = {
      sessionId: ''
    }
  }

  setSessionId () {
    this.props.setSessionId(this.state.sessionId)
    this.setState({sessionId: ''})
  }

  renderDisclaimer () {
    return (
      <Card className='mb-3'>
        <h4 className='mb-3'>ALPHA DISCLAIMER</h4>

        <ul>
          <li>
            This is a <strong>very early alpha</strong> version. Bugs are to be expected. Please report them.
          </li>

          <li>
            This is a <strong>private</strong> alpha. Please do <strong>not</strong> share the login.
            If you have someone who is interested in the tool, message me first.
          </li>

          <li>
            <strong>
              Pricing strategies are currently implemented for
              Divination Cards,
              Splinters,
              Scarabs,
              Essences,
              Fossils,
              Resonators,
              Prophecies,
              Gems,
              Lab Enchants &
              Uniques
            </strong>.
            Everything else will just look up the name and ignore other variables.
          </li>

          <li>
            Right now this uses a pretty hacky method of crawling prices for items, which means
            that only the cheapest ~50 prices on poe.trade are found. This means for high-volume items
            with a lot of past listings you might not get the actual values, since they are pushed
            too far down. Has not happened to me personally yet, but the possiblity is there.
          </li>

          <li>
            When using the "generate forum post" button to create a forum post, in some cases
            the forum post's offers stay in "available" even when you sell them. Not sure why yet,
            not an issue with this tool, just the forum system. Recommended to update frequently.
          </li>
        </ul>
      </Card>
    )
  }

  renderSessionIdForm () {
    return (
      <Card>
        <h4 className='mb-1'>PoE Session ID</h4>
        <small className='d-block text-muted mb-3'>
          This application needs your session ID to read your stash tabs.{' '}

          <a href="https://github.com/Stickymaddness/Procurement/wiki/SessionID" target='_blank'>
            How to find your ID?
          </a>
        </small>

        {this.props.hasSessionId && (
          <div className="alert alert-success">
            You have a Session ID saved, so you should be good to go!
          </div>
        )}

        <input
          className='form-control'
          type='password'
          placeholder='POESESSID'
          onChange={(e) => this.setState({sessionId: e.target.value})}
          value={this.state.sessionId}
        />

        <button
          className="btn btn-primary mt-3 mr-3"
          onClick={() => this.setSessionId()}
          disabled={this.state.sessionId.length !== 32}
        >
          Save
        </button>
      </Card>
    )
  }

  render () {
    return (
      <>
        {this.renderDisclaimer()}
        {this.renderSessionIdForm()}
      </>
    )
  }
}

export default Settings
