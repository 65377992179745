import React, {Component} from 'react'
import {PoeItem} from '../actions/fetchStashTabs'

interface Props {
  items: Array<PoeItem>
}

interface State {
  isCopying: boolean
}

class ForumPostGenerator extends Component<Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = {
      isCopying: false
    }
  }

  generateForumPost () {
    const props = this.props
    this.setState({isCopying: true})

    const stashes = props.items
      .map(x => x.stashLocation.stash)
      .filter((x, i, self) => self.indexOf(x) === i)

    const forumPost = stashes
      .map(stash => this.generateSpoilerTag(props.items, stash))
      .join('\n\n')

    copyToClipboard(forumPost)
    setTimeout(() => this.setState({isCopying: false}), 2000)
  }

  generateSpoilerTag (items: Array<PoeItem>, stash: string) {
    const spoilerTagContent = items
      .filter(item => item.stashLocation.stash === stash)
      .filter(item => item.pricing && item.pricing.usePrice)
      .map(item => {
        if (!item.pricing) return false

        const codeParts = [
          `location="${item.stashLocation.stash}"`,
          `league="${global.CURRENT_LEAGUE}"`,
          `x="${item.stashLocation.x}"`,
          `y="${item.stashLocation.y}"`
        ].join(' ')

        return `  [linkItem ${codeParts}] ~b/o ${item.pricing.amount} ${item.pricing.currency}`
      })
      .filter(Boolean)
      .join('\n')

    return `[spoiler="${stash}"]\n${spoilerTagContent}\n[/spoiler]`
  }

  render () {
    const isCopying = this.state.isCopying

    return (
      <button
        className="btn btn-primary"
        onClick={() => this.generateForumPost()}
        disabled={isCopying}
      >
        {isCopying && <span>Copied to clipboard</span>}
        {!isCopying && <span>Generate forum post</span>}
      </button>
    )
  }
}

function copyToClipboard (string: string): void {
  const element = document.createElement('textarea')
  element.value = string
  document.body.appendChild(element)
  element.select()
  document.execCommand('copy')
  document.body.removeChild(element)
}

export default ForumPostGenerator
