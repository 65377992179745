import React from 'react'

interface Props {
  currentPage: number
  onChange: (page: number) => void
  pageSize: number
  totalElements: number
}

const Pagination = (props: Props) => {
  const totalPages = Math.ceil(props.totalElements / props.pageSize) - 1

  return (
    <div className="d-flex align-items-center justify-content-center">
      <button
        className="btn btn-outline-secondary mr-2"
        onClick={() => props.onChange(0)}
        disabled={props.currentPage === 0}
      >
        First
      </button>

      <button
        className="btn btn-outline-secondary mr-4"
        onClick={() => props.onChange(props.currentPage - 1)}
        disabled={props.currentPage === 0}
      >
        Previous
      </button>

      <div className='mr-4'>
        Page {(props.currentPage + 1).toLocaleString()} of {(totalPages + 1).toLocaleString()}
      </div>

      <button
        className="btn btn-outline-secondary mr-2"
        onClick={() => props.onChange(props.currentPage + 1)}
        disabled={props.currentPage === totalPages}
      >
        Next
      </button>

      <button
        className="btn btn-outline-secondary"
        onClick={() => props.onChange(totalPages)}
        disabled={props.currentPage === totalPages}
      >
        Last
      </button>
    </div>
  )
}

export default Pagination
