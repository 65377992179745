import execall from 'execall'
import average from 'flocky/average'
import {PoeItem, PoeListing} from './fetchStashTabs'

async function fetchItemPrice (item: PoeItem): Promise<PoeItem> {
  const strategy = searchStrategy(item)

  const options = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({strategy: strategy})
  }

  const response = await window.fetch(`/api/${global.CURRENT_LEAGUE}/price-item`, options)
  const json = await response.json()

  const listings = json.map((listing: PoeListing) => {
    listing.timestamp = new Date(listing.timestamp)
    return listing
  })

  return {...item, listings: listings}
}

type ItemMod = [string, string]
type ItemSearchStrategy = Array<ItemMod>

export function searchStrategy (item: PoeItem): ItemSearchStrategy {
  // Gems: Check corruption status, quality and level
  if (item.type === 'gem') {
    return gemSearchStrategy(item)
  }

  // Prophecies: Check the name and the subtext (for master prophecies)
  if (item.properties.prophecyText) {
    return prophecySearchStrategy(item)
  }

  // Enchant bases: Check the item base, item lvl and enchant
  if (item.properties.enchant) {
    return enchantSearchStrategy(item)
  }

  // Unique items: Check corruption status and variable mods
  if (item.properties.unique && item.mods && item.mods.length > 0) {
    return uniqueSearchStrategy(item)
  }

  // Default: Just look up the name
  return defaultSearchStrategy(item)
}

function gemSearchStrategy (item: PoeItem): ItemSearchStrategy {
  let strategy: ItemSearchStrategy = [
    ['name', item.name],
    ['corrupted', item.properties.corrupted ? '1' : '0']
  ]

  if (item.properties.quality) {
    strategy.push(['q_min', item.properties.quality.toString()])
    strategy.push(['q_max', ((item.properties.quality || 0) + 3).toString()])
  }

  if (item.properties.level && item.properties.level >= 19) {
    strategy.push(['level_min', item.properties.level.toString()])
  }

  return strategy
}

function prophecySearchStrategy (item: PoeItem): ItemSearchStrategy {
  return [
    ['name', item.name],
    ['mod_name', '(prophecy) ' + item.properties.prophecyText],
    ['group_count', '1']
  ]
}

function enchantSearchStrategy  (item: PoeItem): ItemSearchStrategy {
  const enchant = item.properties.enchant
  if (!enchant) return []

  let strategy: ItemSearchStrategy = [
    ['name', item.name.replace('Superior ', '')],
    ['corrupted', '0'],
    ['mirrored', '0']
  ]

  if (item.properties.ilvl) {
    strategy.push(['ilvl_min', Math.min(item.properties.ilvl, 84).toString()])
  }

  const enchantValues = execall(/(\d+)/g, enchant).map(x => parseInt(x.match, 10))
  strategy.push(['mod_name', '(enchant) ' + enchant.replace(/\d+/g, '#')])
  strategy.push(['mod_min', enchantValues.length > 0 ? average(enchantValues).toString() : ''])
  strategy.push(['group_count', '1'])

  return strategy
}

function uniqueSearchStrategy  (item: PoeItem): ItemSearchStrategy {
  if (!item.mods) return []

  let strategy: ItemSearchStrategy = [
    ['name', item.name],
    ['corrupted', item.properties.corrupted ? '1' : '0']
  ]

  item.mods.map(mod => {
    strategy.push(['mod_name', mod[0]])
    strategy.push(['mod_min', mod[1] ? (mod[1] * 0.95).toString() : ''])
    strategy.push(['mod_max', ''])
    strategy.push(['mod_weight', ''])
  })

  strategy.push(['group_count', item.mods.length.toString()])
  return strategy
}

function defaultSearchStrategy (item: PoeItem): ItemSearchStrategy {
  return [['name', item.name]]
}

export default fetchItemPrice
