import hash from '@emotion/hash'
import execall from 'execall'
import average from 'flocky/average'

interface PoeApiItem {
  name?: string // name, e.g. "Shavronne's Wrappings"
  typeLine: string // base, e.g. "Blade Flurry"
  icon: string
  stackSize?: number
  properties?: Array<PoeApiItemProperty>
  enchantMods?: Array<string>
  prophecyText?: string
  flavourText?: Array<string>
  explicitMods?: Array<string>
  category?: object
  corrupted?: boolean
  ilvl?: number

  x: number
  y: number
  inventoryId: string
}

interface PoeApiItemProperty {
  name: string // e.g. "Quality"
  values: Array<[string, number]> // e.g. ["+20%", 1]
}

export interface PoeItem {
  hash: string
  blob: string
  count: number
  name: string
  base: string
  type: PoeItemType
  icon: string
  properties: PoeItemProperties
  mods?: PoeItemMods
  category: string
  listings?: Array<PoeListing>
  pricing?: PoePricing
  stashLocation: PoeItemStashLocation
}

type PoeItemType = 'generic' | 'gem' | 'prophecy' | 'enchanted_base'

interface PoeItemProperties {
  corrupted: boolean
  quality?: number
  level?: number
  enchant?: string
  prophecyText?: string
  ilvl?: number
  unique: boolean
}

type PoeItemMods = Array<[string, number]>

export interface PoeListing {
  chaosEquivalent: number
  currency: string
  amount: number
  online: boolean
  seller: string
  timestamp: Date
}

export interface PoePricing {
  usePrice: boolean
  chaosEquivalent: number
  totalChaosEquivalent: number
  amount: number
  currency: 'chaos' | 'exa'
}

interface PoeItemStashLocation {
  stash: string
  x: number
  y: number
}

export default async function fetchStashTabs (indexes: Array<number>): Promise<Array<PoeItem>> {
  // Fetch all the stash tabs in parallel
  const tabs = (await Promise.all(indexes.map(index => fetchStashTab(index))))
    .reduce((a, b) => a.concat(b), [])

  // Go through and calculate the EXACT same items' total count (e.g. prophecies)
  const itemCounts: { [key: string]: number } = {}
  tabs.forEach(item => {
    const hash = itemHash(itemBlob(item))
    itemCounts[hash] = (itemCounts[hash] || 0) + (item.stackSize || 1)
  })

  // Go through the items, remove EXACT same duplicates, and transform into PoeItem
  const existingItems: Array<string> = []
  const items: Array<PoeItem> = []

  tabs.forEach((apiItem: PoeApiItem) => {
    const blob = itemBlob(apiItem)
    const hash = itemHash(blob)

    // Ignore duplicates (already counted up in previous step)
    if (existingItems.includes(hash)) return
    existingItems.push(hash)

    // Transform into the format that we want to work with :)
    let item: PoeItem = {
      hash: hash,
      blob: blob,
      count: itemCounts[hash],
      name: apiItem.name || apiItem.typeLine,
      base: apiItem.typeLine,
      type: 'generic',
      icon: apiItem.icon.split('?')[0],
      properties: {
        corrupted: !!apiItem.corrupted,
        quality: parseProperty(apiItem, 'Quality'),
        level: parseProperty(apiItem, 'Level'),
        enchant: apiItem.enchantMods ? apiItem.enchantMods[0] : undefined,
        prophecyText: apiItem.prophecyText,
        ilvl: apiItem.ilvl,
        unique: !!(
          apiItem.typeLine !== '' &&
          apiItem.name &&
          apiItem.flavourText &&
          apiItem.flavourText.length > 0
        )
      },
      mods: apiItem.explicitMods ? parseItemMods(apiItem.explicitMods) : undefined,
      category: Object.keys(apiItem.category || {})[0],
      stashLocation: {
        stash: apiItem.inventoryId,
        x: apiItem.x,
        y: apiItem.y
      }
    }

    item.type = itemType(item)

    items.push(item)
  })

  return items
}

function parseItemMods (mods: Array<string>): PoeItemMods {
  // Some mods have multiple lines, but are handled as one line by poe.trade
  mods = mods.map(x => x.split('\n')).reduce((a, b) => a.concat(b), [])

  let formatted = []
  for (let mod of mods) {
    // Try to parse numeric modifiers of the item
    const numbers = execall(/(\d+)/g, mod).map(x => parseInt(x.match, 10))
    if (numbers.length === 0) continue

    const singleMod: [string, number] = [mod.replace(/\d+/g, '#'), average(numbers)]
    formatted.push(singleMod)
  }

  return formatted
}

async function fetchStashTab (index: number): Promise<Array<PoeApiItem>> {
  const options = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({sessionId: localStorage.getItem('sessionId')})
  }

  const response = await window.fetch(`/api/${global.CURRENT_LEAGUE}/stash-tab/${index}`, options)
  const json: Array<PoeApiItem> = await response.json()

  return json
}

function itemBlob (item: PoeApiItem) {
  return JSON.stringify([
    item.typeLine,
    item.properties,
    item.enchantMods,
    item.prophecyText,
    item.ilvl
  ]).toLowerCase()
}

function itemHash (blob: string) {
  return hash(blob)
}

function parseProperty (item: PoeApiItem, name: string): number | undefined {
  const properties = item.properties
  if (!properties) return undefined

  const property = properties.find(x => x.name === name)
  if (!property) return undefined

  return parseNumber(property.values[0][0])
}

function parseNumber (string: string): number {
  return parseInt(string.replace('%', '').replace('x', ''), 10)
}

function itemType (item: PoeItem): PoeItemType {
  if (item.category === 'gems') {
    return 'gem'
  }

  if (item.properties.enchant) {
    return 'enchanted_base'
  }

  if (item.properties.prophecyText) {
    return 'prophecy'
  }

  return 'generic'
}
