import React from 'react'
import {PoeStashTab} from '../actions/fetchStashTabList'
import Checkbox from '../../../common/Checkbox'

interface Props {
  tab: PoeStashTab
  checked: boolean
  onChange: (checked: boolean) => void
}

const StashTabSelection = (props: Props) => (
  <div
    className='mb-1 mr-1'
    style={{
      padding: '3px 12px',
      borderRadius: '4px',
      color: props.tab.color,
      background: props.tab.background
    }}
    onClick={(event) => {
      props.onChange(!props.checked)
      event.preventDefault()
    }}
  >
    <Checkbox
      label={props.tab.name}
      checked={props.checked}
      onChange={props.onChange}
    />
  </div>
)

export default StashTabSelection
