import React from 'react'

interface Props {
  children: React.ReactNode
  className?: string
}

const Card = (props: Props) => (
  <div className={'card ' + props.className || ''}>
    <div className="card-body">
      {props.children}
    </div>
  </div>
)

export default Card
